.Preview {
  position: absolute;
  top: 0;
  left: 72.2vw;
  background: rgba(0, 0, 0, 0.1);
  border: 10px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.Preview-board {
  display: grid;
  grid-gap: 2px;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(4, 1fr);
  width: 11vw;
  height: 11vw;
}
