.Board {

  margin: 1em auto;
  display: grid;
  grid-gap: 2px;
  width: clamp(15rem, 35vw, 30rem);
  height: clamp(30rem, 70vw, 60rem);
  background: rgb(32, 0, 64);
  border: 10px solid rgb(32, 0, 64);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
