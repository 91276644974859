.GameStats {
  position: relative;
  width: 22vw;
  list-style-type: none;
  color: rgba(255, 255, 255, 0.5);
}

.GameStats__top {
  width: 100%;
  top: 0;
  text-align: center;
  height: 3em;
}
.GameStats__top li{
  display: inline;
}

.GameStats__left {
  right: 0;
  top: 22vh;
  text-align: left;
}

.GameStats__right {
  right: 0;
  bottom: 0;
  text-align: left;
}

.GameStats .value {
  font-size: 1rem;
  margin-bottom: 0.5em;
  color: rgba(255, 255, 255, 1);
}
