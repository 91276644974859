* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --background-color: #0f0e17;
  --text-color: #fffffe;
  --button: #ff8906;
  --button-text: #fffffe;
  --paragraph: #a7a9be;
  --game-background-color: black;
}

.App {
  background-color: var(--background-color);
  color: var(--text-color);
  text-align: center;
  height: 100vh;
  padding: 2em;
}
